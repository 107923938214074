import React, { ReactElement } from "react";
import { PredictionResult } from "@/types/api/predictions";
import { StyledFormResult } from "@/components/FormIcons/FormIcons.style";

interface FormIconProps {
  form: PredictionResult[];
  height?: string;
  heightForLastMatch?: string;
  width?: string;
  widthForLastMatch?: string;
  margin?: string;
  marginForLastMatch?: string;
  enlargeForLaptops?: boolean;
}

const ariaLabels = new Map<string, string>([
  ["W", "win"],
  ["D", "draw"],
  ["L", "loss"],
  ["LW", "win (last season)"],
  ["LD", "draw (last season)"],
  ["LL", "loss (last season)"],
]);

// Default values for MatchOverview widget
export const FormIcons = ({
  form,
  height = "4px",
  heightForLastMatch = "8px",
  width = "4px",
  widthForLastMatch = "8px",
  margin = "0 0 0 2px",
  marginForLastMatch = "0 2px",
  enlargeForLaptops = true,
}: FormIconProps): ReactElement => {
  return (
    <>
      {form.map((formResult, index) => (
        <StyledFormResult
          aria-label={ariaLabels.get(formResult)}
          key={index}
          result={formResult}
          isLastMatch={index === form.length - 1}
          height={height}
          heightForLastMatch={heightForLastMatch}
          width={width}
          widthForLastMatch={widthForLastMatch}
          margin={margin}
          marginForLastMatch={marginForLastMatch}
          enlargeForLaptops={enlargeForLaptops}
        ></StyledFormResult>
      ))}
    </>
  );
};
