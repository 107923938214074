import {
  CompetitionIdAndSlug,
  PlayerPosition,
  PlayerPositionGroups,
  PredictionLiveDataCard,
  PredictionLiveDataGoal,
  PredictionLiveDataMissedPens,
  PredictionLiveDataSubstitute,
  PredictionStats,
  PredictionStatsDataPercentageTypes,
  PredictionStatsDataTypes,
  PredictionStatsForm,
  PredictionStatsMatch,
  PredictionStatsLineup,
  PredictionStatsStats,
  PredictionStatTypes,
  PredictionTeamLineupPlayer,
  PredictionTeamLineupPlayerStats,
  PredictionTeamLineupPlayerWithTeamName,
  PredictionStatsH2H,
} from "@/types/api/predictions";
import { statPercentageTypes } from "@/constants/predictions";
import { ContentPayload } from "@/types/api/content";
import { PageProps, PaginatedPredictionPageProps } from "@/types/page";

export const isLineupStat = (
  stat: PredictionStatTypes
): stat is PredictionStatsLineup => {
  return (stat as PredictionStatsLineup).type === "lineups";
};

export const filterLineupFromStats = (
  stats: PredictionStats
): PredictionStatsLineup[] => stats.filter(isLineupStat);

export const isStatType = (
  stat: PredictionStatTypes
): stat is PredictionStatsStats => {
  return (stat as PredictionStatsStats).type === "stats";
};

export const filterStatTypeFromStats = (
  stats: PredictionStats
): PredictionStatsStats[] => stats.filter(isStatType);

export const isHomeFormStat = (
  stat: PredictionStatTypes
): stat is PredictionStatsForm => {
  return (stat as PredictionStatsForm).type === "homeForm";
};

export const filterHomeStatForm = (
  stats: PredictionStats
): PredictionStatsForm[] => stats.filter(isHomeFormStat);

export const isAwayFormStat = (
  stat: PredictionStatTypes
): stat is PredictionStatsForm => {
  return (stat as PredictionStatsForm).type === "awayForm";
};

export const filterAwayStatForm = (
  stats: PredictionStats
): PredictionStatsForm[] => stats.filter(isAwayFormStat);

export const isHeadToHeadStat = (
  stat: PredictionStatTypes
): stat is PredictionStatsH2H => {
  return (stat as PredictionStatsH2H).type === "h2h";
};

export const filterHeadToHeadStats = (
  stats: PredictionStats
): PredictionStatsH2H[] => stats.filter(isHeadToHeadStat);

interface PlayerWithStats {
  id: string;
  name: string;
  goals: number;
  ownGoals: number;
  assists: number;
  missedPens: number;
  yellowCards: boolean;
  redCards: boolean;
  subbedOn: boolean;
  subbedOff: boolean;
  positionGroup: PlayerPositionGroups;
}

export const addStatsToPlayers = (
  players: PredictionTeamLineupPlayer[],
  goals: PredictionLiveDataGoal[],
  cards: PredictionLiveDataCard[],
  substitutions: PredictionLiveDataSubstitute[],
  missedPens: PredictionLiveDataMissedPens[]
): PlayerWithStats[] =>
  players.map(({ id, name, position }) => {
    return {
      id,
      name,
      goals: goals.filter(
        ({ scorerId, type }) => type !== "OG" && scorerId === id
      ).length,
      ownGoals: goals.filter(
        ({ scorerId, type }) => type === "OG" && scorerId === id
      ).length,
      assists: goals.filter(({ assistPlayerId }) => assistPlayerId === id)
        .length,
      missedPens: missedPens.filter(({ playerId }) => playerId === id).length,
      yellowCards:
        cards.filter(({ type, playerId }) => type === "YC" && playerId === id)
          .length !== 0,
      redCards:
        cards.filter(
          ({ type, playerId }) =>
            (type === "Y2C" || type === "RC") && playerId === id
        ).length !== 0,
      subbedOn:
        substitutions.filter(({ playerOnId }) => playerOnId === id).length > 0,
      subbedOff:
        substitutions.filter(({ playerOffId }) => playerOffId === id).length >
        0,
      positionGroup: playerPositionToPositionGroup(position),
    };
  });

export const playerPositionToPositionGroup = (
  position: PlayerPosition | undefined
): PlayerPositionGroups => {
  switch (position) {
    case "Goalkeeper":
      return "G";
    case "Defender":
    case "Wing Back":
      return "D";
    case "Attacking Midfielder":
    case "Defensive Midfielder":
    case "Midfielder":
      return "M";
    case "Striker":
      return "S";
    case "Substitute":
      return "SUB";
    default:
      return "";
  }
};

export const isPercentageStatType = (
  type: PredictionStatsDataTypes
): type is PredictionStatsDataPercentageTypes => {
  return statPercentageTypes.includes(
    type as PredictionStatsDataPercentageTypes
  );
};

export const resultFromMatch = (
  match: PredictionStatsMatch,
  activeTeamPosition: "Home" | "Away"
): "W" | "D" | "L" => {
  const { homeScore, awayScore } = match;
  const activeTeamScore = activeTeamPosition === "Home" ? homeScore : awayScore;
  const opponentScore = activeTeamPosition === "Home" ? awayScore : homeScore;

  if (activeTeamScore > opponentScore) return "W";
  if (activeTeamScore === opponentScore) return "D";
  return "L";
};

export const removeLineupPlayersWithStatValueOfZero = (
  players: PredictionTeamLineupPlayer[],
  stat: keyof PredictionTeamLineupPlayerStats
): PredictionTeamLineupPlayer[] =>
  players.filter(({ stats }) => stats[stat] > 0);

export const appendTeamNameToLineupPlayers = (
  players: PredictionTeamLineupPlayer[],
  teamName: string
): PredictionTeamLineupPlayerWithTeamName[] =>
  players.map((player) => {
    return { ...player, teamName };
  });

export const sortLineupPlayersWithTeamNameByStatValue = (
  players: PredictionTeamLineupPlayerWithTeamName[],
  stat: keyof PredictionTeamLineupPlayerStats
): PredictionTeamLineupPlayerWithTeamName[] =>
  players.sort((playerA, playerB) => {
    if (stat === "shots" && playerA.stats.shots === playerB.stats.shots) {
      if (playerA.stats.shotsOnTarget > playerB.stats.shotsOnTarget) {
        return -1;
      }
      if (playerA.stats.shotsOnTarget < playerB.stats.shotsOnTarget) {
        return 1;
      }
      return 0;
    }
    if (playerA.stats[stat] > playerB.stats[stat]) {
      return -1;
    }
    if (playerA.stats[stat] < playerB.stats[stat]) {
      return 1;
    }
    return 0;
  });

export const getPredictionsByCompetitionId = (
  predictionsArray: ContentPayload[],
  compId: string
) => {
  return predictionsArray.filter((prediction: ContentPayload) => {
    return prediction.meta.match?.competition_id === compId;
  }, []);
};

export const findCompetitionIdFromSlug = ({
  competitions,
  slug,
}: {
  competitions: CompetitionIdAndSlug[];
  slug: string;
}) => competitions.find((competition) => competition.slug === slug)?._id;

// Type guard to allow us to render pagination/content layout
export function checkIsPaginatedPredictionPagePropsInsteadOfPageProps(
  data: PageProps | PaginatedPredictionPageProps
): data is PaginatedPredictionPageProps {
  return (data as PaginatedPredictionPageProps).predictions !== undefined;
}
