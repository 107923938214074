import { ReactElement } from "react";
import Image from "next/image";
import { PredictionTeamLineup } from "@/types/api/predictions";
import { teamArrayToCommaSeperatedString } from "@/lib/array";
import {
  StyledSubs,
  StyledTeamName,
  StyledTeamNameAndBadge,
} from "@/components/TeamLineupPreMatch/TeamLineupPreMatch.style";
import { idToTeamBadgeImageUrl } from "@/lib/media";

interface TeamLineupProps {
  lineup: PredictionTeamLineup;
}

export const TeamLineupPreMatch = ({
  lineup: { teamName, teamId, first11, subs },
}: TeamLineupProps): ReactElement => (
  <div data-testid="teamLineupPreMatch">
    <StyledTeamNameAndBadge>
      <Image
        alt={`${teamName} badge`}
        src={idToTeamBadgeImageUrl(teamId)}
        width={32}
        height={32}
      />
      <StyledTeamName>{teamName}</StyledTeamName>
    </StyledTeamNameAndBadge>
    <p aria-label={`${teamName} first eleven`}>
      {teamArrayToCommaSeperatedString(first11)}
    </p>
    <StyledSubs aria-label={`${teamName} subs`}>
      {teamArrayToCommaSeperatedString(subs)}
    </StyledSubs>
  </div>
);
