import { ReactElement, useState } from "react";
import {
  PredictionLiveDataLineups,
  PredictionTeamLineupPlayer,
  PredictionTeamLineupPlayerStats,
  PredictionTeamLineupPlayerWithTeamName,
} from "@/types/api/predictions";
import {
  appendTeamNameToLineupPlayers,
  removeLineupPlayersWithStatValueOfZero,
  sortLineupPlayersWithTeamNameByStatValue,
} from "@/lib/predictions";
import { PlayerStats } from "@/components/PlayerStats/PlayerStats";
import { ButtonGroup } from "@/components/ButtonGroup/ButtonGroup";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";
import styles from "@/components/MatchPlayerStats/MatchPlayerStats.module.scss";

const filterAndFormatTeam = (
  players: PredictionTeamLineupPlayer[],
  activeStat: keyof PredictionTeamLineupPlayerStats,
  teamName: string
): PredictionTeamLineupPlayerWithTeamName[] => {
  const activeStatData = removeLineupPlayersWithStatValueOfZero(
    players,
    activeStat
  );
  return appendTeamNameToLineupPlayers(activeStatData, teamName);
};

const filterAndFormatLineups = (
  lineups: PredictionLiveDataLineups,
  activeStat: keyof PredictionTeamLineupPlayerStats
): {
  home: PredictionTeamLineupPlayerWithTeamName[];
  away: PredictionTeamLineupPlayerWithTeamName[];
} => ({
  home: filterAndFormatTeam(
    [...lineups.home.first11, ...lineups.home.subs],
    activeStat,
    lineups.home.teamName
  ),
  away: filterAndFormatTeam(
    [...lineups.away.first11, ...lineups.away.subs],
    activeStat,
    lineups.away.teamName
  ),
});

type ActiveStat = keyof PredictionTeamLineupPlayerStats;

const sortedAndFormatLineups = (
  lineups: PredictionLiveDataLineups,
  activeStat: ActiveStat,
  statsAreCollapsed: boolean
): PredictionTeamLineupPlayerWithTeamName[] => {
  const { home, away } = filterAndFormatLineups(lineups, activeStat);
  const allSortedPlayersFromBothTeams =
    sortLineupPlayersWithTeamNameByStatValue([...home, ...away], activeStat);

  const collapsedLength =
    allSortedPlayersFromBothTeams.length >= 5
      ? 5
      : allSortedPlayersFromBothTeams.length;

  return statsAreCollapsed
    ? allSortedPlayersFromBothTeams.slice(0, collapsedLength)
    : allSortedPlayersFromBothTeams;
};

const matchStatButtons = ["shots", "passes", "tackles"];
type MatchStatButton = "shots" | "passes" | "tackles";
const isValidButton = (value: string): value is MatchStatButton =>
  matchStatButtons.includes(value);

interface MatchPlayerStatsProps {
  lineups: PredictionLiveDataLineups;
}

export const MatchPlayerStats = ({
  lineups,
}: MatchPlayerStatsProps): ReactElement => {
  const [activeStat, setActiveStat] = useState<ActiveStat>("shots");
  const [statsAreCollapsed, setStatsCollapsed] = useState<boolean>(true);
  const [activeStats, setActiveStats] = useState<
    PredictionTeamLineupPlayerWithTeamName[]
  >(sortedAndFormatLineups(lineups, activeStat, statsAreCollapsed));

  const onClickHandler = (name: string) => {
    if (isValidButton(name)) {
      setActiveStat(name);
      setActiveStats(sortedAndFormatLineups(lineups, name, statsAreCollapsed));
    }
  };

  const buttons = [
    {
      name: "shots",
      text: "Shots (On Target)",
      onClick: onClickHandler,
      active: activeStat === "shots",
    },
    {
      name: "passes",
      text: "Passes",
      onClick: onClickHandler,
      active: activeStat === "passes",
    },
    {
      name: "tackles",
      text: "Tackles",
      onClick: onClickHandler,
      active: activeStat === "tackles",
    },
  ];

  const statsCollapseButtonName = statsAreCollapsed ? "open" : "collapse";

  const toggleCollapseState = () => {
    const statsAreNowCollapsed = !statsAreCollapsed;

    setStatsCollapsed(statsAreNowCollapsed);
    setActiveStats(
      sortedAndFormatLineups(lineups, activeStat, statsAreNowCollapsed)
    );
  };

  return (
    <MatchStatContainer header="Player Stats">
      <>
        <ButtonGroup buttons={buttons} />
        <PlayerStats players={activeStats} stat={activeStat} />
        <button
          className={styles.toggleButton}
          name={statsCollapseButtonName}
          onClick={toggleCollapseState}
        >
          {statsCollapseButtonName}
        </button>
      </>
    </MatchStatContainer>
  );
};
