import styled from "styled-components";

export const StyledBookingStatPairContainer = styled.div<{ reverse: boolean }>`
  display: flex;
  flex-direction: ${({ reverse }) => (reverse ? "row-reverse" : "row")};
  align-items: center;
`;

export const StyledCardCount = styled.span<{ reverse: boolean }>`
  margin: ${({ reverse }) => (reverse ? "0 0.5rem 0 1rem" : "0 1rem 0 0.5rem")};
  font-size: 0.75rem;
  font-weight: 500;
`;
