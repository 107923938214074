import { ReactElement, useState } from "react";
import { PredictionStats } from "@/types/api/predictions";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";
import { filterStatTypeFromStats } from "@/lib/predictions";
import { MatchStatRow } from "@/components/MatchStatRow/MatchStatRow";
import { ButtonGroup } from "@/components/ButtonGroup/ButtonGroup";

const matchStatButtons = ["last5", "season", "h2h"];

type MatchStatButton = "last5" | "season" | "h2h";
const isValidButton = (value: string): value is MatchStatButton =>
  matchStatButtons.includes(value);

interface MatchStatsProps {
  stats: PredictionStats;
}

export const MatchStats = ({ stats }: MatchStatsProps): ReactElement => {
  const [activeButton, setActiveButton] = useState<MatchStatButton>("last5");

  const onClickHandler = (name: string) => {
    if (isValidButton(name)) setActiveButton(name);
  };

  const buttons = [
    {
      name: "last5",
      text: "Last 5",
      onClick: onClickHandler,
      active: activeButton === "last5",
    },
    {
      name: "season",
      text: "Season",
      onClick: onClickHandler,
      active: activeButton === "season",
    },
    {
      name: "h2h",
      text: "Head to Head",
      onClick: onClickHandler,
      active: activeButton === "h2h",
    },
  ];

  const allStats = filterStatTypeFromStats(stats)[0];
  const activeStats = allStats[activeButton];

  return (
    <MatchStatContainer header="Combined Stats">
      <>
        <div>
          <ButtonGroup buttons={buttons} />
        </div>
        <MatchStatRow stats={activeStats} />
      </>
    </MatchStatContainer>
  );
};
