import { PREDICTIONS_CONTENT_LIMIT } from "@/constants/api";

export const goalTypes = ["G", "PG", "OG"] as const;
export const cardTypes = ["YC", "Y2C", "RC"] as const;
export const statPercentageTypes = ["btts", "over15", "over25"] as const;
export const allStatTypes = [
  "goals",
  "cards",
  "corners",
  ...statPercentageTypes,
] as const;

export const predictionsLimit = PREDICTIONS_CONTENT_LIMIT;
