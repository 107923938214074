import { ReactElement } from "react";
import styles from "@/components/MatchStatContainer/MatchStatContainer.module.scss";

interface MatchStatContainerProps {
  header: string;
  children: ReactElement;
}

export const MatchStatContainer = ({
  header,
  children,
}: MatchStatContainerProps): ReactElement => (
  <div className={styles.container} data-testid="MatchStatContainer">
    <h3>{header}</h3>
    {children}
  </div>
);
