export const SubOffIcon = () => (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Sub Off</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-1836.000000, -197.000000)"
        fill="#E72035"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <g id="Group-3">
              <path
                d="M140.989299,104.285932 L142.481362,102.78587 L142.481362,111.437477 C142.481362,111.74815 142.736331,112 143.050851,112 C143.365371,112 143.62034,111.74815 143.62034,111.437477 L143.62034,101.732076 L144,101.357061 L144.37966,101.732076 L144.37966,111.437477 C144.37966,111.74815 144.634629,112 144.949149,112 C145.263669,112 145.518638,111.74815 145.518638,111.437477 L145.518638,102.78212 L147.010701,104.282182 C147.151307,104.433688 147.364373,104.497387 147.566436,104.448326 C147.768498,104.399264 147.927387,104.245253 147.980859,104.046623 C148.034332,103.847993 147.973875,103.636369 147.823172,103.494649 L145.355385,101.012047 C145.290482,100.949072 145.210976,100.902725 145.123792,100.877041 L144.402439,100.164512 C144.180097,99.9451627 143.819903,99.9451627 143.597561,100.164512 L142.876208,100.877041 C142.789024,100.902725 142.709518,100.949072 142.644615,101.012047 L140.176828,103.498399 C140.026125,103.640119 139.965668,103.851743 140.019141,104.050373 C140.072613,104.249003 140.231502,104.403014 140.433564,104.452076 C140.635627,104.501137 140.848693,104.437439 140.989299,104.285932 L140.989299,104.285932 Z"
                id="Sub-Off"
                transform="translate(144.000000, 106.000000) scale(1, -1) translate(-144.000000, -106.000000) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
