import { ReactElement } from "react";
import {
  PredictionTeamLineupPlayerStats,
  PredictionTeamLineupPlayerWithTeamName,
} from "@/types/api/predictions";
import { PlayerStat } from "@/components/PlayerStat/PlayerStat";
import { StyledPlayerStatsContainer } from "@/components/PlayerStats/PlayerStats.style";

interface PlayerStatsProps {
  players: PredictionTeamLineupPlayerWithTeamName[];
  stat: keyof PredictionTeamLineupPlayerStats;
}

export const PlayerStats = ({
  players,
  stat,
}: PlayerStatsProps): ReactElement => (
  <StyledPlayerStatsContainer>
    {players.map((player) => (
      <PlayerStat player={player} stat={stat} key={player.id} />
    ))}
  </StyledPlayerStatsContainer>
);
