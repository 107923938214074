import styled from "styled-components";

export const StyledTeamNameAndBadge = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
`;

export const StyledTeamName = styled.h4`
  margin: 0;
  font-size: 0.8rem;
  font-weight: normal;
  padding-left: 0.5rem;
`;

export const StyledSubs = styled.p`
  color: ${({ theme }) => theme.subText};
`;
