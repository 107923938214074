import { ReactElement } from "react";
import { PercentageProgressBar } from "@/components/PercentageProgressBar/PercentageProgressBar";
import themeSelector from "@/brands/theme";
import { getActiveBrand } from "@/lib/brand";
import {
  StyledAwayStat,
  StyledHomeStat,
  StyledPercentageStatPairContainer,
  StyledProgressBarPairContainer,
  StyledStatsWithTitleContainer,
  StyledStatTitle,
} from "@/components/PercentageStatPair/PercentageStatPair.style";
import { homeAndAwayValuesToPercentage } from "@/lib/number";

interface PercentageStatPairProps {
  homeStatValue: number;
  homeTeamName: string;
  awayStatsValue: number;
  awayTeamName: string;
  title: string;
  labelAsPercentage?: boolean;
}

const brand = getActiveBrand();
const theme = themeSelector(brand);
const homeColor = theme?.drawSolidColour || "rgb(239, 229, 46)";
const awayColor = theme?.winSolidColour || "rgb(68, 219, 94)";

export const PercentageStatPair = ({
  homeStatValue,
  homeTeamName,
  awayStatsValue,
  awayTeamName,
  title,
  labelAsPercentage = false,
}: PercentageStatPairProps): ReactElement => {
  const percentages = homeAndAwayValuesToPercentage(
    homeStatValue,
    awayStatsValue
  );

  return (
    <StyledPercentageStatPairContainer>
      <StyledStatsWithTitleContainer>
        <StyledStatTitle>{title}</StyledStatTitle>
        <StyledHomeStat aria-label={`${homeTeamName} ${title}`}>
          {labelAsPercentage ? `${homeStatValue}%` : homeStatValue}
        </StyledHomeStat>
        <StyledAwayStat aria-label={`${awayTeamName} ${title}`}>
          {labelAsPercentage ? `${awayStatsValue}%` : awayStatsValue}
        </StyledAwayStat>
      </StyledStatsWithTitleContainer>
      <StyledProgressBarPairContainer>
        <PercentageProgressBar
          statName={`${homeTeamName} ${title}`}
          percentageComplete={percentages.home}
          color={homeColor}
          fromRight={true}
          addTitle={true}
        />
        <PercentageProgressBar
          statName={`${awayTeamName} ${title}`}
          percentageComplete={percentages.away}
          color={awayColor}
          addTitle={true}
        />
      </StyledProgressBarPairContainer>
    </StyledPercentageStatPairContainer>
  );
};
