export const PRE_EVENT_STATUSES = ["Fixture"] as const;
export const IN_PLAY_EVENT_STATUSES = ["Playing"] as const;
export const POST_EVENT_STATUSES = ["Awarded", "Played"] as const;
export const CANCELLED_EVENT_STATUSES = [
  "Cancelled",
  "Postponed",
  "Suspended",
] as const;
export const ALL_EVENT_STATUSES = [
  "Unknown",
  ...PRE_EVENT_STATUSES,
  ...IN_PLAY_EVENT_STATUSES,
  ...POST_EVENT_STATUSES,
  ...CANCELLED_EVENT_STATUSES,
] as const;
