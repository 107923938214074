import { BASE_ODDS_PLATFORM_API_URL } from "@/constants/api";
import { PredictionMarketSelection } from "@/types/api/predictions";
import { BrandPredictionCodes } from "@/types/brand";

export const createBookmakerBetSlipLink = ({
  cleansedBookmakerName,
  bookmakerPredictionCode,
  offerId,
}: {
  cleansedBookmakerName: string;
  bookmakerPredictionCode?: BrandPredictionCodes[keyof BrandPredictionCodes];
  offerId: PredictionMarketSelection["id"];
}) =>
  `${BASE_ODDS_PLATFORM_API_URL}tipster/trackBet?bookmaker=${cleansedBookmakerName}&selections=${encodeURIComponent(
    offerId
  )}${bookmakerPredictionCode ? `&affcode=${bookmakerPredictionCode}` : ""}`;
