import styled from "styled-components";
import { devices } from "@/constants/devices";

export const StyledPlayerWithStats = styled.span`
  width: 100%;
  box-sizing: border-box;
  height: 1.75rem;
  display: flex;
  justify-content: space-between;
  padding-right: 0.25rem;

  svg {
    margin-left: 0.25rem;
  }
`;

export const StyledPlayerWithStatsName = styled.span`
  max-width: 90px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;

  @media only screen and ${devices.laptop} {
    max-width: 150px;
  }
`;

export const StyledStatIconContainer = styled.div`
  display: flex;
  padding-left: 0.5rem;
`;
