import styled from "styled-components";
import { devices } from "@/constants/devices";

export const StyledPlayerStatContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  width: 320px;

  @media only screen and ${devices.laptop} {
    width: 49%;
    max-width: 320px;
  }
`;

export const StyledPlayerStatName = styled.span`
  flex: 4 1 0;
  font-size: 0.625rem;
  padding-right: 0.5rem;
  text-align: left;
`;

export const StyledPlayerStatTeam = styled.span`
  flex: 2 1 0;
  font-size: 0.625rem;
  font-weight: 500;
  margin-left: auto;
  text-align: left;
`;

export const StyledPlayerStatValue = styled.span`
  flex: 1 0 0;
  padding: 0.5rem;
  font-size: 0.75rem;
  font-weight: 700;
  color: ${({ theme }) => theme.activeTabButtonText};
  background-color: ${({ theme }) => theme.activeElement};
  border-radius: 4px;
  text-align: center;
  margin-left: 0.5rem;
`;
