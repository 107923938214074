import styled from "styled-components";
import { devices } from "@/constants/devices";

export const StyledPercentageStatPairContainer = styled.div`
  padding-bottom: 1rem;
  width: 100%;

  @media only screen and ${devices.laptop} {
    width: 48%;
  }
`;

export const StyledStatsWithTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledStatTitle = styled.h4`
  order: 2;
  font-size: 0.68rem;
  font-weight: 500;
  margin: 0;
`;

const StyledStat = styled.span`
  font-size: 0.75rem;
`;

export const StyledHomeStat = styled(StyledStat)`
  order: 1;
`;

export const StyledAwayStat = styled(StyledStat)`
  order: 3;
`;

export const StyledProgressBarPairContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
`;
