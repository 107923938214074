import { ReactElement } from "react";
import Image from "next/image";
import styles from "@/components/HeadToHeadRow/HeadToHeadRow.module.scss";
import {
  idToCompetitionBadgeImageUrl,
  idToTeamBadgeImageUrl,
} from "@/lib/media";
import { fullDateToShortDate } from "@/lib/date";
import { PredictionStatsMatch } from "@/types/api/predictions";

interface HeadToHeadRowProps {
  match: PredictionStatsMatch;
}

export const HeadToHeadRow = ({
  match,
}: HeadToHeadRowProps): ReactElement | null => {
  const homeTeam = match.contestant.find(
    (contestant) => contestant.position === "home"
  );

  const awayTeam = match.contestant.find(
    (contestant) => contestant.position === "away"
  );

  if (!homeTeam || !awayTeam) return null;

  return (
    <div
      key={match.id}
      className={styles.headToHeadRow}
      data-testid="headToHeadRow"
    >
      <div className={styles.competitionBadge}>
        <Image
          src={idToCompetitionBadgeImageUrl(match.competitionID)}
          alt={"Competition logo"}
          width={27}
          height={27}
        />
      </div>

      <div className={styles.teamBadgeHome}>
        <Image
          src={idToTeamBadgeImageUrl(homeTeam?.id)}
          width={27}
          height={27}
          alt={`${homeTeam.name} team logo`}
        />
      </div>

      <div
        className={`${styles.score} ${styles.home} ${
          match.homeScore > match.awayScore ? styles.win : ""
        }`}
        data-testid="homeScore"
      >
        <span>{match.homeScore}</span>
      </div>

      <div
        className={`${styles.score} ${styles.away} ${
          match.awayScore > match.homeScore ? styles.win : ""
        }`}
        data-testid="awayScore"
      >
        <span>{match.awayScore}</span>
      </div>

      <div className={styles.teamBadgeAway}>
        <Image
          src={idToTeamBadgeImageUrl(awayTeam?.id)}
          width={27}
          height={27}
          alt={`${awayTeam.name} team logo`}
        />
      </div>

      <span className={styles.date}>{fullDateToShortDate(match.date)}</span>
    </div>
  );
};
