import { ReactElement } from "react";
import { YellowCardIcon } from "@/components/icons/YellowCardIcon";
import { RedCardIcon } from "@/components/icons/RedCardIcon";
import {
  StyledBookingStatPairContainer,
  StyledCardCount,
} from "@/components/BookingStatPair/BookStatPair.style";

interface BookingStatPairProps {
  yellowCardCount?: number;
  redCardCount?: number;
  label: string;
  reverse?: boolean;
}

export const BookingStatPair = ({
  yellowCardCount = 0,
  redCardCount = 0,
  label,
  reverse = false,
}: BookingStatPairProps): ReactElement => (
  <StyledBookingStatPairContainer reverse={reverse}>
    <YellowCardIcon />
    <StyledCardCount
      aria-label={`${label} yellow card count`}
      reverse={reverse}
    >
      {yellowCardCount}
    </StyledCardCount>
    <RedCardIcon />
    <StyledCardCount aria-label={`${label} red card count`} reverse={reverse}>
      {redCardCount}
    </StyledCardCount>
  </StyledBookingStatPairContainer>
);
