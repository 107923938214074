import styled, { css } from "styled-components";
import { PredictionResult } from "@/types/api/predictions";
import { devices } from "@/constants/devices";

const laptopCss = (isLastMatch: boolean) =>
  css`
    @media only screen and ${devices.laptop} {
      height: ${isLastMatch ? "16px" : "12px"};
      width: ${isLastMatch ? "16px" : "12px"};
      margin: 0 5px;
    }
  `;

interface StyledFormResultProps {
  result: PredictionResult;
  isLastMatch: boolean;
  height: string;
  heightForLastMatch: string;
  width: string;
  widthForLastMatch: string;
  margin: string;
  marginForLastMatch: string;
  enlargeForLaptops: boolean;
}

export const StyledFormResult = styled.li<StyledFormResultProps>`
  display: initial;
  box-sizing: border-box;
  background-color: ${({ result, theme }) => {
    switch (result) {
      case "W":
        return theme.winSolidColour;
      case "D":
        return theme.drawSolidColour;
      case "L":
        return theme.lossSolidColour;
    }
  }};
  border: ${({ result, theme }) => {
    switch (result) {
      case "LW":
        return `1px solid ${theme.winSolidColour}`;
      case "LD":
        return `1px solid ${theme.drawSolidColour}`;
      case "LL":
        return `1px solid ${theme.lossSolidColour}`;
    }
  }};
  height: ${({ isLastMatch, height, heightForLastMatch }) =>
    isLastMatch ? heightForLastMatch : height};
  width: ${({ isLastMatch, width, widthForLastMatch }) =>
    isLastMatch ? widthForLastMatch : width};
  border-radius: 50%;
  list-style: none;
  margin: ${({ isLastMatch, margin, marginForLastMatch }) =>
    isLastMatch ? marginForLastMatch : margin};

  ${({ enlargeForLaptops, isLastMatch }) =>
    enlargeForLaptops && laptopCss(isLastMatch)}
`;
