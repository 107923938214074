import styles from "./ButtonPill.module.scss";

export const ButtonPill = ({
  active = false,
  onClick,
  children,
}: {
  active?: boolean;
  onClick: () => void;
  children: React.ReactNode;
}) => (
  <button aria-pressed={active} onClick={onClick} className={styles.button}>
    {children}
  </button>
);
