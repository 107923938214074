import { ReactElement } from "react";
import {
  PredictionStats,
  PredictionStatsFormType,
} from "@/types/api/predictions";
import { filterAwayStatForm, filterHomeStatForm } from "@/lib/predictions";
import { PreviousMatchResults } from "@/components/PreviousMatchResults/PreviousMatchResults";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";

interface MatchTeamFormProps {
  stats: PredictionStats;
  type: PredictionStatsFormType;
}

export const MatchTeamForm = ({
  stats,
  type,
}: MatchTeamFormProps): ReactElement => {
  const {
    matches,
    team: { id, name },
  } =
    type === "homeForm"
      ? filterHomeStatForm(stats)[0]
      : filterAwayStatForm(stats)[0];

  return (
    <MatchStatContainer header={`${name} • Form`}>
      <PreviousMatchResults matches={matches} activeTeamId={id} />
    </MatchStatContainer>
  );
};
