import { PRE_EVENT_STATUSES, POST_EVENT_STATUSES } from "@/constants/event";
import {
  EventStatus,
  PreEventStatus,
  PostEventStatus,
} from "@/types/api/event";

export function isBeforeEvent(status: EventStatus): status is PreEventStatus {
  return PRE_EVENT_STATUSES.includes(status as PreEventStatus);
}

export function isAfterEvent(status: EventStatus): status is PostEventStatus {
  return POST_EVENT_STATUSES.includes(status as PostEventStatus);
}
