import styled from "styled-components";

export const StyledPercentageProgressBarContainer = styled.div`
  background-color: ${({ theme }) => theme.secondary};
  height: 6px;
  border-radius: 3px;
  position: relative;
  width: calc(50% - 0.25rem);
`;

export const StyledPercentageBarComplete = styled.div<{
  width: number;
  color: string;
  fromRight: boolean;
}>`
  width: ${({ width }) => `${width}%`};
  background-color: ${({ color }) => color};
  height: 6px;
  border-radius: 3px;
  position: absolute;
  ${({ fromRight }) => (fromRight ? `right: 0` : `left: 0`)};
  transition: width 0.3s linear 0s;
`;
