import { ReactElement } from "react";
import { PredictionStatsData } from "@/types/api/predictions";
import { wholeNumberOr2DecimalPlaces } from "@/lib/number";
import styles from "@/components/MatchStat/MatchStat.module.scss";

interface MatchStatProps {
  stat: PredictionStatsData;
  percentageStat?: boolean;
}

export const MatchStat = ({
  stat: { name, combined, type },
  percentageStat = false,
}: MatchStatProps): ReactElement => {
  const formattedValue = percentageStat
    ? `${wholeNumberOr2DecimalPlaces(combined)}%`
    : wholeNumberOr2DecimalPlaces(combined);

  return (
    <div className={styles.container}>
      <h4 id={type}>{name}</h4>
      <p
        className={
          percentageStat && combined >= 70 ? styles.positiveStatColor : ""
        }
        aria-labelledby={type}
      >
        {formattedValue}
      </p>
    </div>
  );
};
