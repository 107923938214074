export const SubOnIcon = () => (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Sub On</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-2276.000000, -131.000000)"
        fill="#44DB5E"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <g id="Group-4" transform="translate(440.000000, 0.000000)">
              <path
                d="M140.989299,38.2859318 L142.481362,36.7858699 L142.481362,45.4374768 C142.481362,45.7481498 142.736331,46 143.050851,46 C143.365371,46 143.62034,45.7481498 143.62034,45.4374768 L143.62034,35.7320765 L144,35.357061 L144.37966,35.7320765 L144.37966,45.4374768 C144.37966,45.7481498 144.634629,46 144.949149,46 C145.263669,46 145.518638,45.7481498 145.518638,45.4374768 L145.518638,36.7821198 L147.010701,38.2821817 C147.151307,38.4336884 147.364373,38.4973872 147.566436,38.4483256 C147.768498,38.3992639 147.927387,38.2452528 147.980859,38.046623 C148.034332,37.8479932 147.973875,37.6363688 147.823172,37.4946492 L145.355385,35.0120468 C145.290482,34.949072 145.210976,34.9027248 145.123792,34.8770412 L144.402439,34.1645118 C144.180097,33.9451627 143.819903,33.9451627 143.597561,34.1645118 L142.876208,34.8770412 C142.789024,34.9027248 142.709518,34.949072 142.644615,35.0120468 L140.176828,37.4983993 C140.026125,37.6401189 139.965668,37.8517433 140.019141,38.0503732 C140.072613,38.249003 140.231502,38.403014 140.433564,38.4520757 C140.635627,38.5011374 140.848693,38.4374385 140.989299,38.2859318 L140.989299,38.2859318 Z"
                id="Sub-On"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
