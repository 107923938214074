import {
  PredictionContestant,
  PredictionLiveDataGoal,
  PredictionLiveStatBase,
} from "@/types/api/predictions";

type StatWitContestantId = Pick<PredictionLiveStatBase, "contestantId">;

export const filterStatsByContestantId = <T extends StatWitContestantId>(
  stats: T[],
  contestant: PredictionContestant
): T[] => stats.filter((stat) => stat.contestantId === contestant.id);

export const filterGoalsForTeam = (
  stats: PredictionLiveDataGoal[],
  contestant: PredictionContestant
) =>
  stats.filter((stat) => {
    return (
      (stat.contestantId === contestant.id && stat.type !== "OG") ||
      (stat.contestantId !== contestant.id && stat.type === "OG")
    );
  });
