import { ReactElement } from "react";
import {
  StyledIconAndCountContainer,
  StyledIconCount,
} from "@/components/IconWithCount/IconWithCount.style";

export type IconCountTypes = "Goal" | "Own Goal" | "Assist" | "Missed Penalty";

interface IconWithCountProps {
  count: number;
  label: IconCountTypes;
  children: ReactElement;
}

export const IconWithCount = ({
  count,
  label,
  children,
}: IconWithCountProps): ReactElement =>
  count === 1 ? (
    children
  ) : (
    <StyledIconAndCountContainer aria-label={`${label} count`}>
      <span aria-hidden={true} data-testid="svg-container">
        {children}
      </span>
      <StyledIconCount type={label}>{count}</StyledIconCount>
    </StyledIconAndCountContainer>
  );
