import { ReactElement } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import {
  PredictionContestant,
  PredictionResponse,
  PredictionResult,
  PredictionStats,
} from "@/types/api/predictions";
import { Tabs } from "@/components/Tabs/Tabs";
import { MatchEvents } from "@/components/MatchEvents/MatchEvents";
import { MatchGeneralStats } from "@/components/MatchGeneralStats/MatchGeneralStats";
import { TeamLineupRenderer } from "@/components/TeamLineupRenderer/TeamLineupRenderer";
import { MatchPlayerStats } from "@/components/MatchPlayerStats/MatchPlayerStats";
import { MatchStats } from "@/components/MatchStats/MatchStats";
import { MatchTeamForm } from "@/components/MatchTeamForm/MatchTeamForm";
import { filterLineupFromStats } from "@/lib/predictions";
import { MatchTime } from "@/components/MatchTime/MatchTime";
import { EventStatus } from "@/types/api/event";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";
import { FormIcons } from "@/components/FormIcons/FormIcons";
import {
  removeLastSegmentFromPath,
  removePagesQueryAndLocaleFromPath,
} from "@/lib/string";
import { MatchHeadToHead } from "@/components/MatchHeadToHead/MatchHeadToHead";
import { LinkNoPrefetch } from "@/components/LinkNoPrefetch/LinkNoPrefetch";
import styles from "@/components/MatchOverview/MatchOverview.module.scss";
import { isBeforeEvent } from "@/lib/event";
import { ContentMatchOverviewWidget } from "@/types/api/content";
import { MatchOverviewInsights } from "../MatchOverviewInsights/MatchOverviewInsights";
import { MatchOverviewSpecials } from "../matchOverviewSpecials/MatchOverviewSpecials";

const statsHaveLineup = (stats: PredictionStats): boolean => {
  const lineupStats = filterLineupFromStats(stats);

  if (lineupStats.length > 0 && lineupStats[0].home && lineupStats[0].away) {
    return true;
  }
  return false;
};

const generateTabs = ({ match, stats }: PredictionResponse) => {
  switch (match.status) {
    case "Fixture":
    case "Cancelled":
    case "Postponed":
      const hasLineup = stats ? statsHaveLineup(stats) : false;

      return hasLineup
        ? {
            Stats: stats ? (
              <MatchStats stats={stats} />
            ) : (
              <div>No stats available</div>
            ),
            Lineups: (
              <MatchStatContainer header="Lineups">
                <div className={styles.preMatchTeamLineupContainer}>
                  <TeamLineupRenderer
                    match={match}
                    stats={stats}
                    position="home"
                  />
                  <TeamLineupRenderer
                    match={match}
                    position="away"
                    stats={stats}
                  />
                </div>
              </MatchStatContainer>
            ),
            [`${match.contestant[0].code} form`]: stats ? (
              <MatchTeamForm stats={stats} type="homeForm" key="homeForm" />
            ) : (
              <div>No stats available</div>
            ),
            [`${match.contestant[1].code} form`]: stats ? (
              <MatchTeamForm stats={stats} type="awayForm" key="awayForm" />
            ) : (
              <div>No stats available</div>
            ),
            H2H: stats ? (
              <MatchHeadToHead stats={stats} />
            ) : (
              <div>No stats available</div>
            ),
          }
        : {
            Stats: stats ? (
              <MatchStats stats={stats} />
            ) : (
              <div>No stats available</div>
            ),
            [`${match.contestant[0].code} form`]: stats ? (
              <MatchTeamForm stats={stats} type="homeForm" key="homeForm" />
            ) : (
              <div>No stats available</div>
            ),
            [`${match.contestant[1].code} form`]: stats ? (
              <MatchTeamForm stats={stats} type="awayForm" key="awayForm" />
            ) : (
              <div>No stats available</div>
            ),
            H2H: stats ? (
              <MatchHeadToHead stats={stats} />
            ) : (
              <div>No stats available</div>
            ),
          };
    default:
      return {
        Overview: (
          <MatchEvents
            contestant={match.contestant}
            liveData={match.liveData}
          />
        ),
        General:
          match.liveData && match.liveData.lineups ? (
            <MatchGeneralStats lineups={match.liveData.lineups} />
          ) : (
            <div>No stats available</div>
          ),
        [match.contestant[0].code]: (
          <TeamLineupRenderer match={match} position="home" stats={stats} />
        ),
        [match.contestant[1].code]: (
          <TeamLineupRenderer match={match} position={"away"} />
        ),
        Player: match.liveData?.lineups ? (
          <MatchPlayerStats lineups={match.liveData?.lineups} />
        ) : (
          <div>No stats available</div>
        ),
      };
  }
};

type TeamProps = Pick<PredictionContestant, "id" | "name" | "position"> & {
  form: PredictionResult[] | [];
  eventStatus: EventStatus;
};

const Team = ({
  id,
  name,
  position,
  form,
  eventStatus,
}: TeamProps): ReactElement => (
  <div className={styles.team}>
    <div className={styles.teamBadgeContainer}>
      <Image
        alt={`${position} Team badge`}
        src={`https://cdn.checkd-dev.com/football/teams/150x150/${id}.png`}
        width={110}
        height={110}
      />
    </div>
    <p className={styles.teamName}>{name}</p>
    <ul
      className={`${styles.form} ${
        eventStatus === "Fixture" ? styles.fixture : ""
      }`}
      aria-label={`${name} form, oldest to newest`}
    >
      <FormIcons form={form} />
    </ul>
  </div>
);

export type MatchOverviewProps = Pick<ContentMatchOverviewWidget, "data">;

export const MatchOverview = ({
  data,
}: MatchOverviewProps): ReactElement | null => {
  const { asPath } = useRouter();

  if (!data) return null;

  const tabs = generateTabs(data.prediction);
  const parentLink = removeLastSegmentFromPath(
    removePagesQueryAndLocaleFromPath(asPath)
  );
  const { boosts, givemeodds, match } = data.prediction;

  const isBefore = isBeforeEvent(match.status);

  const backgroundImage = match.meta.venue?.id;

  return (
    <>
      <div
        className={styles.metaContainer}
        style={{
          backgroundImage:
            backgroundImage &&
            `url(https://cdn.fantasyiteam.com/bethub/venues/600x450/${backgroundImage}.jpg)`,
        }}
        itemScope
        itemType="http://schema.org/SportsEvent"
        data-testid={`matchOverview-${match.id}`}
      >
        <div className={styles.transparentOverlay}>
          <div className={styles.scoreboard}>
            <div className={styles.meta}>
              <LinkNoPrefetch className={styles.competition} href={parentLink}>
                {match.meta.competition.name}
              </LinkNoPrefetch>
              <span className={styles.state}>
                <MatchTime match={match} />
              </span>
            </div>
            <div className={styles.teamsAndScores}>
              <div className={styles.teamAndScore}>
                <Team
                  name={match.contestant[0].name}
                  id={match.contestant[0].id}
                  position="home"
                  form={match.form.home}
                  eventStatus={match.status}
                />
                {match.status !== "Fixture" && (
                  <span
                    className={`${styles.goal} ${styles.home}`}
                    data-testid="home-goals"
                  >
                    {match.liveData?.matchDetails.scores.total.home}
                  </span>
                )}
              </div>
              {match.status === "Fixture" && (
                <span className={styles.vsSpan}>vs</span>
              )}
              <div className={`${styles.teamAndScore} ${styles.away}`}>
                <Team
                  name={match.contestant[1].name}
                  id={match.contestant[1].id}
                  position="away"
                  form={match.form.away}
                  eventStatus={match.status}
                />
                {match.status !== "Fixture" && (
                  <span className={styles.goal} data-testid="away-goals">
                    {match.liveData?.matchDetails.scores.total.away}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tabs tabs={tabs} />
      {isBefore ? <MatchOverviewInsights insights={match.insights} /> : null}
      {/* Render Specials if its before the match and there are any boosts OR givemeodds, bookmaker data and predictionCodes */}
      {isBefore &&
      [...givemeodds, ...boosts].length > 0 &&
      data.bookmakers &&
      data.bookmakers.length > 0 &&
      data.predictionCodes ? (
        <MatchOverviewSpecials
          boosts={boosts}
          givemeodds={givemeodds}
          bookmakers={data.bookmakers}
          predictionCodes={data.predictionCodes}
        />
      ) : null}
    </>
  );
};
