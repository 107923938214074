import { ReactElement } from "react";
import {
  StyledPercentageBarComplete,
  StyledPercentageProgressBarContainer,
} from "@/components/PercentageProgressBar/PercentageProgressBar.style";
import { numberWithinRange } from "@/lib/number";

interface ProgressBarProps {
  statName: string;
  percentageComplete: number;
  color: string;
  fromRight?: boolean;
  addTitle?: boolean;
}

export const PercentageProgressBar = ({
  percentageComplete,
  statName,
  color,
  fromRight = false,
  addTitle = false,
}: ProgressBarProps): ReactElement => {
  const barWidth = numberWithinRange(percentageComplete, 0, 100);

  return (
    <StyledPercentageProgressBarContainer
      role="progressbar"
      aria-hidden={addTitle}
    >
      <StyledPercentageBarComplete
        title={addTitle ? "" : `${statName} ${percentageComplete}%`}
        width={barWidth}
        color={color}
        fromRight={fromRight}
        data-testid={`${statName} ${percentageComplete}%`}
      ></StyledPercentageBarComplete>
    </StyledPercentageProgressBarContainer>
  );
};
