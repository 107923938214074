import { ReactElement } from "react";
import {
  StyledCircle,
  StyledProgressCircleText,
} from "@/components/ProgressCircle/ProgressCircle.style";
import { wholeNumberOr1DecimalPlace } from "@/lib/number";

interface ProgressCircleProps {
  progress: number;
  size: string;
  fill?: string;
  barWidth?: number;
  barColor?: string;
}

export const ProgressCircle = ({
  progress,
  size,
  fill = "#1E4536",
  barWidth = 4,
  barColor = "#44DB5E",
}: ProgressCircleProps): ReactElement => {
  const dashArray = 2 * Math.PI * 90;
  const dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <svg
      aria-label="progressCircle"
      width={size}
      height={size}
      viewBox="0 0 200 200"
    >
      <g transform="rotate(-90 100 100)">
        <StyledCircle
          cx={100}
          cy={100}
          r={90}
          fill={fill}
          strokeWidth={barWidth}
        />
        <StyledCircle
          cx={100}
          cy={100}
          r={95}
          fill="transparent"
          stroke={fill}
          strokeWidth={barWidth}
        />
        <StyledCircle
          cx={100}
          cy={100}
          r={95}
          fill="transparent"
          stroke={barColor}
          strokeWidth={barWidth}
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
        />
      </g>
      <StyledProgressCircleText
        x="50%"
        y="50%"
        dominantBaseline="central"
        textAnchor="middle"
        fontSize="3.2rem"
        fill={barColor}
      >
        {wholeNumberOr1DecimalPlace(progress)}%
      </StyledProgressCircleText>
    </svg>
  );
};
