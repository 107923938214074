import React, {
  Dispatch,
  ReactElement,
  useContext,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import { Acca } from "@/types/api/smartAcca";
import { twoDecimalFloor } from "@/lib/number";
import { fullDateToShortDateWithTime } from "@/lib/date";
import SmartAccaLogo from "@/public/images/SmartAccaLogo.png";
import { ProgressCircle } from "@/components/ProgressCircle/ProgressCircle";
import {
  BetSlipDispatchContext,
  BetSlipStateContext,
} from "@/contexts/betSlipContext";
import {
  BetSlipActions,
  BetSlipState,
  CommonAndEventIdPair,
} from "@/types/betslip";
import styles from "@/components/SuggestedSmartAcca/SuggestedSmartAcca.module.scss";
import { AngleLeftCircle } from "@/components/icons/AngleLeftCircle";
import { AngleRightCircle } from "@/components/icons/AngleRightCircle";
import { getFormattedOdds } from "@/lib/odds";
import { LinkNoPrefetch } from "../LinkNoPrefetch/LinkNoPrefetch";

export interface SuggestedSmartAccaProps {
  accas: Acca[];
}

export const SuggestedSmartAcca = ({
  accas,
}: SuggestedSmartAccaProps): ReactElement => {
  const dispatch = useContext(
    BetSlipDispatchContext
  ) as Dispatch<BetSlipActions>;
  const { activeCommonAndEventIdPairs, oddsFormat } = useContext(
    BetSlipStateContext
  ) as BetSlipState;

  // This is used to track which element we have scrolled to
  const [scrollPositionX, setScrollPositionX] = useState<number>(0);

  // Give the scrollable container a ref
  const cardsContainerRef = useRef<HTMLDivElement>(null);

  const scrollInDirection = (direction: "LEFT" | "RIGHT"): void => {
    const container = cardsContainerRef.current;
    const containerCardChild =
      container && (container.children[0] as HTMLDivElement);

    if (container && containerCardChild) {
      // Determine the new scroll position by adding the card width amount and the gap px to the current scroll position
      const newScrollLeft =
        direction === "LEFT"
          ? container.scrollLeft - containerCardChild.offsetWidth
          : container.scrollLeft + containerCardChild.offsetWidth;

      container.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (direction: "LEFT" | "RIGHT"): void => {
    scrollInDirection(direction);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    setScrollPositionX(target.scrollLeft);
  };

  const isScrolledToEnd =
    cardsContainerRef.current &&
    scrollPositionX ===
      cardsContainerRef.current.scrollWidth -
        cardsContainerRef.current.clientWidth
      ? true
      : false;

  const hasButtonBeenPressed = (
    currentSelection: CommonAndEventIdPair[]
  ): boolean => {
    // A button is pressed if every selection from an acca exists within the active selection pairs
    // Also check the length so an acca with fewer selections doesn't cause any issues
    return currentSelection.every(
      (pair) =>
        activeCommonAndEventIdPairs.findIndex(
          ({ commonID, eventId }) =>
            commonID === pair.commonID && eventId === pair.eventId
        ) !== -1 &&
        currentSelection.length === activeCommonAndEventIdPairs.length
    );
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.cardListHeaderContainer}
        data-testid="suggestedSmartAccaHeader"
      >
        <div className={styles.headerAndLogoContainer}>
          <h2>Suggested</h2>
          <Image src={SmartAccaLogo} alt="Smart Acca" width={108} height={15} />
        </div>

        <div className={styles.arrowButtonsContainer}>
          <button
            onClick={() => handleClick("LEFT")}
            disabled={scrollPositionX === 0}
          >
            <AngleLeftCircle size={32} />
          </button>
          <button
            onClick={() => handleClick("RIGHT")}
            disabled={isScrolledToEnd}
          >
            <AngleRightCircle size={32} />
          </button>
        </div>
      </div>

      <div
        className={styles.smartAccaCardsContainer}
        ref={cardsContainerRef}
        onScroll={handleScroll}
      >
        {accas.map(
          ({ selections, type, oddsDecimal, odds, oddsAmerican }, index) => {
            const commonAndSelectionIdPairsArray = selections.map(
              (selection) => ({
                commonID: selection.commonID,
                eventId: selection.fixtureId,
              })
            );

            return (
              <div
                className={styles.smartAccaCard}
                key={index}
                data-testid="acca"
              >
                <h3>{type}</h3>
                <p>£10.00 returns £{twoDecimalFloor(10 * oddsDecimal)}</p>

                <div className={styles.selectionsContainer}>
                  {selections.map(
                    (
                      {
                        marketName,
                        selectionName,
                        odds,
                        oddsDecimal,
                        oddsAmerican,
                        homeTeamName,
                        awayTeamName,
                        fixtureDate,
                        probability,
                      },
                      accaSelectionIndex
                    ) => {
                      const selectionDetails = `${
                        marketName !== "Match Result" ? marketName + " " : ""
                      }${
                        selectionName !== "Yes" ? selectionName : ""
                      } @ ${getFormattedOdds(
                        { odds, oddsDecimal, oddsAmerican },
                        oddsFormat
                      )}`;

                      const fixtureDetails = `${homeTeamName} v ${awayTeamName} • ${fullDateToShortDateWithTime(
                        fixtureDate
                      )}`;

                      return (
                        <div
                          className={styles.selection}
                          key={accaSelectionIndex}
                          data-testid="selection"
                        >
                          <ProgressCircle
                            progress={probability}
                            size={"38px"}
                            barWidth={10}
                          />
                          <div className={styles.selectionDataContainer}>
                            <p className={styles.selectionDetails}>
                              {selectionDetails}
                            </p>
                            <p
                              className={styles.fixtureDetails}
                              suppressHydrationWarning
                            >
                              {fixtureDetails}
                            </p>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>

                <button
                  onClick={() => {
                    dispatch({
                      type: "ADD_ACCA",
                      commonAndSelectionIdPairsArray,
                      source: "Suggested Smart Acca",
                    });
                  }}
                  aria-pressed={hasButtonBeenPressed(
                    commonAndSelectionIdPairsArray
                  )}
                >
                  Add To Bet Slip At{" "}
                  {getFormattedOdds(
                    { odds, oddsAmerican, oddsDecimal },
                    oddsFormat
                  )}
                </button>
              </div>
            );
          }
        )}
      </div>

      <div className={styles.footerContainer}>
        <p>
          Results display the percentage of matches that have been BTTS, Over
          2.5 Goals & Won or Drawn in the last 5 matches in all major
          Competitions and International Friendlies
        </p>

        <LinkNoPrefetch className={styles.footerLink} href={"/smart-acca"}>
          <span>Powered by</span>
          <Image src={SmartAccaLogo} alt="Smart Acca" width={91} height={12} />
        </LinkNoPrefetch>
      </div>
    </div>
  );
};
