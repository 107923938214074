import { ReactElement } from "react";
import day from "dayjs";
import { PredictionsMatch } from "@/types/api/predictions";
import { formatMatchTime } from "@/lib/football";
import styles from "@/components/MatchTime/MatchTime.module.scss";

const FullTime = () => (
  <>
    <span className={styles.desktopOnlySpan}>Full Time</span>
    <span className={styles.mobileAndTabletSpan}>FT</span>
  </>
);

interface MatchTimeProps {
  match: PredictionsMatch;
}

export const MatchTime = ({
  match: { status, date, liveData },
}: MatchTimeProps): ReactElement => {
  switch (status) {
    case "Fixture":
      return (
        <span className={styles.desktopOnlySpan} suppressHydrationWarning>
          {day(date).format("DD/MM/YYYY HH:mm")}
        </span>
      );
    case "Playing":
      return (
        <span className={styles.desktopOnlySpan}>
          {liveData ? `Live ${formatMatchTime(liveData.matchDetails)}` : status}
        </span>
      );
    case "Played":
      return <FullTime />;
    default:
      return <p>{status}</p>;
  }
};
