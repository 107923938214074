import { MouseEvent, ReactElement, useState } from "react";
import styles from "@/components/Tabs/Tabs.module.scss";

interface TabsInterface {
  [key: string]: ReactElement;
}

interface TabsProps {
  tabs: TabsInterface;
  activeUnderline?: boolean;
}

export const Tabs = ({ tabs, activeUnderline }: TabsProps): ReactElement => {
  const [activeButton, setActiveButton] = useState<string>(
    Object.keys(tabs)[0]
  );
  const [activeContent, setActiveContent] = useState<ReactElement>(
    Object.values(tabs)[0]
  );
  const buttons = Object.keys(tabs).map((key) => key);

  const onClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const buttonName = event.currentTarget.name;

    const content = tabs[buttonName];

    setActiveButton(buttonName);
    setActiveContent(content);
  };

  return (
    <div>
      <div className={styles["tab__button-container"]}>
        {buttons.map((button) => (
          <button
            type="button"
            key={button}
            aria-pressed={button === activeButton}
            onClick={onClick}
            name={button}
            className={`${styles["tab__button"]} ${
              activeUnderline ? styles["underline"] : styles["no-underline"]
            }`.trim()}
          >
            {button}
          </button>
        ))}
      </div>
      <div className={styles["tab__content-container"]}>{activeContent}</div>
    </div>
  );
};
