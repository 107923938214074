import { useEffect, useRef, useState } from "react";
import Image from "next/image";
import { PredictionResponse } from "@/types/api/predictions";
import { BookmakerPayload } from "@/types/api/bookmaker";
import { BrandPredictionCodes } from "@/types/brand";
import { createBookmakerBetSlipLink } from "@/lib/bookmaker";
import { bannerLogoUrlFromName } from "@/lib/media";
import { rgbTupleToString } from "@/lib/color";
import { useCheckMobileDevice } from "@/hooks/Window";
import { gaEvent } from "@/lib/gtag";
import { ButtonPill } from "../ButtonPill/ButtonPill";
import CardWrapper from "../CardWrapper/CardWrapper";
import styles from "./MatchOverviewSpecials.module.scss";

interface MatchOverviewSpecialsProps {
  boosts: PredictionResponse["boosts"];
  givemeodds: PredictionResponse["givemeodds"];
  bookmakers: BookmakerPayload[];
  predictionCodes: BrandPredictionCodes;
}

export const MatchOverviewSpecials = ({
  boosts,
  givemeodds,
  bookmakers,
  predictionCodes,
}: MatchOverviewSpecialsProps) => {
  const isMobileDevice = useCheckMobileDevice();
  const [offerCount, setOfferCount] = useState(isMobileDevice ? 2 : 4);
  const [showAll, toggleShowAll] = useState(false);
  const [activeOffers, setActiveOffers] = useState(boosts.slice(0, offerCount));
  const [activeCategory, setActiveCategory] = useState<"boosts" | "givemeodds">(
    boosts.length > 0 ? "boosts" : "givemeodds"
  );
  const allOffers = activeCategory === "boosts" ? boosts : givemeodds;
  const matchOverviewSpecialsRef = useRef<HTMLDivElement>(null);

  const scrollToRef = () => {
    if (!matchOverviewSpecialsRef.current) return;
    window.scrollTo({
      top: matchOverviewSpecialsRef.current.offsetTop - 150,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (showAll) {
      setActiveOffers(allOffers);
    } else {
      setActiveOffers(allOffers.slice(0, offerCount));
    }
  }, [allOffers, showAll, activeCategory, boosts, givemeodds, offerCount]);

  useEffect(() => {
    setOfferCount(isMobileDevice ? 2 : 4);
  }, [isMobileDevice]);

  const toggleClicked = () => {
    if (showAll) {
      toggleShowAll(!showAll);
      scrollToRef();
    } else {
      toggleShowAll(!showAll);
    }
  };

  const offerPressedEvent = ({
    bookmaker,
    offer,
    site_id,
  }: {
    bookmaker: string;
    offer: string;
    site_id: string;
  }): void => {
    gaEvent("offer_pressed", {
      bookmaker,
      category: activeCategory,
      offer,
      site_id,
    });
  };

  return (
    <div ref={matchOverviewSpecialsRef}>
      <CardWrapper listHeader="SPECIALS">
        <div data-testid="specials-buttons" className={styles.buttonGroup}>
          {boosts?.length > 0 ? (
            <ButtonPill
              active={activeCategory === "boosts"}
              onClick={() => setActiveCategory("boosts")}
            >
              Boosts
            </ButtonPill>
          ) : null}
          {givemeodds?.length > 0 ? (
            <ButtonPill
              active={activeCategory === "givemeodds"}
              onClick={() => setActiveCategory("givemeodds")}
            >
              #BUILDABETS
            </ButtonPill>
          ) : null}
        </div>

        <div className={styles.matchOverviewSpecialsContainer}>
          {activeOffers.map((offer) => {
            const bookmaker = bookmakers.find(
              (bookmaker) => bookmaker.name_cleansed === offer.bookmaker
            );

            if (!bookmaker) return null;

            const bookmakerPredictionCode =
              predictionCodes[bookmaker?.name_cleansed];

            return (
              <div
                key={offer._id}
                data-testid="offer"
                style={{
                  backgroundColor: `${rgbTupleToString(
                    bookmaker.branding.primary
                  )}`,
                  color: `${rgbTupleToString(bookmaker.branding.text)}`,
                }}
                className={styles.matchOverviewSpecial}
              >
                <div className={styles.bookmaker}>
                  {
                    <Image
                      src={bannerLogoUrlFromName(bookmaker.name_cleansed)}
                      width={36}
                      height={36}
                      alt={`${bookmaker.name} Logo`}
                    />
                  }
                  <span className={styles.bookmakerName}>{bookmaker.name}</span>
                </div>
                <p>{offer.name}</p>
                {
                  <a
                    href={createBookmakerBetSlipLink({
                      cleansedBookmakerName: bookmaker.name_cleansed,
                      bookmakerPredictionCode,
                      offerId: offer.id,
                    })}
                    aria-label={`Place a bet with ${offer.bookmaker}`}
                    className={styles.bookmakerLink}
                    onClick={() =>
                      offerPressedEvent({
                        bookmaker: offer.bookmaker,
                        offer: offer.name,
                        site_id: bookmakerPredictionCode,
                      })
                    }
                    style={{
                      backgroundColor: `${rgbTupleToString(
                        bookmaker.branding.button.primary
                      )}`,
                      color: `${rgbTupleToString(
                        bookmaker.branding.button.text
                      )}`,
                    }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {offer.odds}
                  </a>
                }
              </div>
            );
          })}
        </div>
        {allOffers.length > offerCount ? (
          <div className={styles.toggleContainer}>
            <button onClick={toggleClicked}>
              {showAll ? "View Less" : "View All"}
            </button>
          </div>
        ) : null}
      </CardWrapper>
    </div>
  );
};
