import { ReactElement } from "react";
import Image from "next/image";
import day from "dayjs";
import { PredictionStatsMatch } from "@/types/api/predictions";
import {
  idToCompetitionBadgeImageUrl,
  idToTeamBadgeImageUrl,
} from "@/lib/media";
import { resultFromMatch } from "@/lib/predictions";
import styles from "@/components/PreviousMatchResult/PreviousMatchResult.module.scss";

interface PreviousMatchResultProps {
  match: PredictionStatsMatch;
  activeTeamId: string;
}

export const PreviousMatchResult = ({
  match,
  activeTeamId,
}: PreviousMatchResultProps): ReactElement => {
  const { contestant, competitionID, date, homeScore, awayScore } = match;
  const opponent = contestant.find(({ id }) => id !== activeTeamId);
  const opponentBadgeUrl = opponent && idToTeamBadgeImageUrl(opponent.id);
  const competitionBadgeUrl = idToCompetitionBadgeImageUrl(competitionID);
  const isActiveTeamHome =
    contestant.findIndex(({ id }) => id === activeTeamId) === 0;
  const position = isActiveTeamHome ? "Home" : "Away";
  const result = resultFromMatch(match, position);

  return (
    <section className={styles.result} aria-label="result">
      <div className={styles.badgeContainer}>
        {opponentBadgeUrl && (
          <Image
            src={opponentBadgeUrl}
            alt="Opposing Team Badge"
            height={27}
            width={27}
          />
        )}
        <div className={styles.competitionBadgeContainer}>
          <Image
            src={competitionBadgeUrl}
            alt="Competition Badge"
            height={16}
            width={16}
          />
        </div>
      </div>
      <div>
        <section className={styles.metaContent} aria-label="meta">
          {day(date).format("DD MMM YYYY")} • {position}
        </section>
        {opponent && (
          <section className={styles.opponentName} aria-label="opponent">
            {opponent.name}
          </section>
        )}
      </div>
      <p
        className={[styles["outcome"], styles[`outcome--${result}`]].join(" ")}
      >
        {result}
      </p>
      <section className={styles.scoreContainer} aria-label="score">
        <p>{homeScore}</p>
        <p>{awayScore}</p>
      </section>
    </section>
  );
};
