import styled from "styled-components";
import { IconCountTypes } from "@/components/IconWithCount/IconWithCount";

export const StyledIconAndCountContainer = styled.p`
  position: relative;
  margin-bottom: 0;
  line-height: 1rem;
`;

export const StyledIconCount = styled.span<{
  type: IconCountTypes;
}>`
  position: absolute;
  top: 0.5rem;
  right: 0;
  width: 0.75rem;
  height: 0.75rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  color: ${({ theme, type }) => {
    switch (type) {
      case "Goal":
      case "Assist":
        return theme.defaultText;
      case "Own Goal":
      case "Missed Penalty":
        return theme.contentHeaderFontColor;
    }
  }};
  background-color: ${({ theme, type }) => {
    switch (type) {
      case "Goal":
        return theme.matchStat.goalIconCountColor;
      case "Own Goal":
        return theme.matchStat.ownGoalIconCountColor;
      case "Assist":
        return theme.matchStat.assistIconCountColor;
      case "Missed Penalty":
        return theme.matchStat.missedPenIconCountColor;
    }
  }};
  border: ${({ theme }) => `1ps solid ${theme.matchStat.iconCountBorderColor}`};
  border-radius: 50%;
`;
