export const RedCardIcon = () => (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Red Card</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-1836.000000, -230.000000)"
        fill="#E72035"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <g id="Group-3">
              <rect
                id="Red-Card"
                x="140"
                y="133"
                width="8"
                height="12"
                rx="2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
