import styled from "styled-components";

export const StyledCircle = styled.circle`
  &:first-of-type {
    transform: scale(0.88) translate(13px,13px);
`;

export const StyledProgressCircleText = styled.text`
  font-weight: bold;
  fill: ${({ theme }) => theme.progressCircle.fontColor};
`;
