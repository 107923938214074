import { ReactElement } from "react";
import { PredictionStatsDataArray } from "@/types/api/predictions";
import { MatchStat } from "@/components/MatchStat/MatchStat";
import { isPercentageStatType } from "@/lib/predictions";
import styles from "@/components/MatchStatRow/MatchStatRow.module.scss";

interface MatchStatsProps {
  stats: PredictionStatsDataArray;
}

export const MatchStatRow = ({ stats }: MatchStatsProps): ReactElement => (
  <div className={styles.container}>
    {stats.map((stat) => {
      const isPercentage = isPercentageStatType(stat.type);

      return (
        <MatchStat stat={stat} percentageStat={isPercentage} key={stat.type} />
      );
    })}
  </div>
);
