import React, { ReactElement } from "react";

export const YellowCardIcon = (): ReactElement => (
  <svg
    width="8px"
    height="12px"
    viewBox="0 0 8 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Yellow Card</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-1824.000000, -197.000000)"
        fill="#EFE52E"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <g id="Group-3">
              <rect
                id="Yellow-Card"
                x="128"
                y="100"
                width="8"
                height="12"
                rx="2"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
