import { ReactElement } from "react";
import {
  PredictionLiveDataCard,
  PredictionLiveDataGoal,
  PredictionLiveDataMissedPens,
  PredictionLiveDataSubstitute,
  PredictionTeamLineup,
  PredictionTeamLineupPlayer,
} from "@/types/api/predictions";
import { PlayerWithStatIcons } from "@/components/PlayerWithStatIcons/PlayerWithStatIcons";
import { addStatsToPlayers } from "@/lib/predictions";
import {
  StyledLineup,
  StyledPlayer,
} from "@/components/TeamLineupRenderer/TeamLineupDuringMatch.style";

interface TeamLineupDuringMatchProp {
  lineup: PredictionTeamLineup;
  substitutions?: PredictionLiveDataSubstitute[];
  goals?: PredictionLiveDataGoal[];
  cards?: PredictionLiveDataCard[];
  missedPens?: PredictionLiveDataMissedPens[];
}

export const TeamLineupDuringMatch = ({
  lineup: { first11, subs },
  substitutions = [],
  goals = [],
  cards = [],
  missedPens = [],
}: TeamLineupDuringMatchProp): ReactElement => {
  // Made substitutions are passed under the substitutions prop but these include both teams substitutions
  // To unify the shape of player data and only show the subs for the team we map against the subs within the lineup data
  const usedSubs = substitutions.reduce(
    (acc: PredictionTeamLineupPlayer[], { playerOnId }) => {
      const player = subs.find(({ id }) => id === playerOnId);
      if (player) acc.push(player);
      return acc;
    },
    []
  );
  const first11WithStats = addStatsToPlayers(
    first11,
    goals,
    cards,
    substitutions,
    missedPens
  );
  const usedSubsWithStats = addStatsToPlayers(
    usedSubs,
    goals,
    cards,
    substitutions,
    missedPens
  );

  return (
    <div data-testid="teamLineupDuringMatch">
      <StyledLineup>
        {first11WithStats.map(
          ({
            name,
            id,
            goals,
            ownGoals,
            missedPens,
            assists,
            yellowCards,
            redCards,
            subbedOff,
            subbedOn,
            positionGroup,
          }) => (
            <StyledPlayer key={id}>
              <PlayerWithStatIcons
                name={name}
                goals={goals}
                ownGoals={ownGoals}
                assists={assists}
                missedPens={missedPens}
                yellowCards={yellowCards}
                redCards={redCards}
                subbedOn={subbedOn}
                subbedOff={subbedOff}
                positionGroup={positionGroup}
              />
            </StyledPlayer>
          )
        )}
        {usedSubsWithStats.map(
          ({
            name,
            id,
            goals,
            ownGoals,
            assists,
            missedPens,
            yellowCards,
            redCards,
            subbedOff,
            subbedOn,
            positionGroup,
          }) => (
            <StyledPlayer key={id}>
              <PlayerWithStatIcons
                name={`${name}`}
                goals={goals}
                ownGoals={ownGoals}
                assists={assists}
                missedPens={missedPens}
                yellowCards={yellowCards}
                redCards={redCards}
                subbedOff={subbedOff}
                subbedOn={subbedOn}
                positionGroup={positionGroup}
              />
            </StyledPlayer>
          )
        )}
      </StyledLineup>
    </div>
  );
};
