import styled from "styled-components";
import { devices } from "@/constants/devices";

export const StyledPlayerStatsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  @media only screen and ${devices.laptop} {
    justify-content: space-between;
  }
`;
