export const OwnGoalIcon = () => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Own Goal</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-2052.000000, -230.000000)"
        fill="#E72035"
        fillRule="nonzero"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <path
              d="M362,133 C365.313708,133 368,135.686292 368,139 C368,142.313708 365.313708,145 362,145 C358.686292,145 356,142.313708 356,139 C356,135.686292 358.686292,133 362,133 Z M362,133.9 C359.183348,133.9 356.9,136.183348 356.9,139 C356.9,141.816652 359.183348,144.1 362,144.1 C364.816652,144.1 367.1,141.816652 367.1,139 C367.1,136.183348 364.816652,133.9 362,133.9 Z M360.618843,141.521883 L361.197354,143.174316 C360.222922,142.98807 359.366601,142.469087 358.75116,141.740133 L358.742377,141.522522 L360.618843,141.521883 Z M363.589914,141.521883 L365.310008,141.522522 L365.304838,141.672405 C364.698664,142.4211 363.843226,142.959703 362.864783,143.161956 L363.589914,141.521883 Z M362.026192,137.372211 L363.746286,138.649034 L363.120797,140.564267 L361.026036,140.564267 L360.306099,138.649034 L362.026192,137.372211 Z M358.956607,136.414595 L359.211493,138.170225 L357.75067,139.076249 L357.75,139 L357.75,139 C357.75,138.04899 358.062361,137.170937 358.590084,136.462837 L358.956607,136.414595 Z M365.095778,136.414595 L365.404014,136.454937 C365.935309,137.1644 366.25,138.04545 366.25,139 L366.249,139.043 L364.840891,138.170225 L365.095778,136.414595 Z M362,134.75 C362.450762,134.75 362.885133,134.820175 363.292786,134.950195 L363.433542,135.156606 L362.026192,136.254992 L360.460125,135.230342 L360.635379,134.973819 C361.063695,134.828695 361.522658,134.75 362,134.75 Z"
              id="Own-Goal"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
