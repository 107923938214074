import { ReactElement } from "react";
import { PredictionsMatch, PredictionStats } from "@/types/api/predictions";
import { TeamLineupPreMatch } from "@/components/TeamLineupPreMatch/TeamLineupPreMatch";
import { filterLineupFromStats } from "@/lib/predictions";
import { TeamLineupDuringMatch } from "@/components/TeamLineupDuringMatch/TeamLineupDuringMatch";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";

interface TeamLineupRendererProps {
  match: PredictionsMatch;
  position: "home" | "away";
  stats?: PredictionStats;
}

const MissingTeamLineup = () => <div>No lineup available</div>;

/***
 *
 * We require different behaviour based on the match status.
 * This component is used to ensure the correct component is rendered with the correct data
 */
export const TeamLineupRenderer = ({
  match: { status, liveData },
  position,
  stats,
}: TeamLineupRendererProps): ReactElement => {
  const isBeforeMatch = status === "Fixture";

  if (isBeforeMatch) {
    if (!stats) {
      return <MissingTeamLineup />;
    }

    const lineup = filterLineupFromStats(stats)[0][position];

    if (!lineup) return <MissingTeamLineup />;

    return (
      <>
        <TeamLineupPreMatch lineup={lineup} />
      </>
    );
  }

  if (!liveData || !liveData.lineups) return <MissingTeamLineup />;

  const { substitute, card, goal, missedPen } = liveData;

  return (
    <MatchStatContainer
      header={`${liveData.lineups[position].teamName} Lineup`}
    >
      <TeamLineupDuringMatch
        lineup={liveData.lineups[position]}
        substitutions={substitute}
        cards={card}
        goals={goal}
        missedPens={missedPen}
      />
    </MatchStatContainer>
  );
};
