import { ReactElement } from "react";
import { FootballIcon } from "@/components/icons/FootballIcon";
import { filterGoalsForTeam, filterStatsByContestantId } from "@/lib/stats";
import {
  PredictionLiveDataCard,
  PredictionLiveDataGoal,
  PredictionsMatch,
} from "@/types/api/predictions";
import { YellowCardIcon } from "@/components/icons/YellowCardIcon";
import { RedCardIcon } from "@/components/icons/RedCardIcon";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";
import styles from "@/components/MatchEvents/MatchEvents.module.scss";

type MatchCardProps = Pick<PredictionLiveDataCard, "playerName" | "timeMin">;

const MatchCard = ({ timeMin, playerName }: MatchCardProps) => (
  <li className={styles.stat}>{`(${timeMin}') ${playerName}`}</li>
);

type MatchCardRow = Pick<PredictionsMatch, "contestant"> & {
  homeCards: PredictionLiveDataCard[];
  awayCards: PredictionLiveDataCard[];
  cardType: "red" | "yellow";
  hasCards: boolean;
};

const MatchCardRow = ({
  contestant,
  homeCards,
  awayCards,
  cardType,
  hasCards,
}: MatchCardRow): ReactElement => (
  <div className={styles.statRow}>
    <ul aria-label={`${contestant[0].name} ${cardType} cards`}>
      {homeCards.map(
        ({ playerName, timeMin }) =>
          playerName && (
            <MatchCard
              playerName={playerName}
              timeMin={timeMin}
              key={`${playerName}-${cardType}`}
            />
          )
      )}
    </ul>
    {hasCards && (cardType === "yellow" ? <YellowCardIcon /> : <RedCardIcon />)}
    <ul aria-label={`${contestant[1].name} ${cardType} cards`}>
      {awayCards.map(
        ({ playerName, timeMin }) =>
          playerName && (
            <MatchCard
              playerName={playerName}
              timeMin={timeMin}
              key={`${playerName}-${cardType}`}
            />
          )
      )}
    </ul>
  </div>
);

type MatchGoalProps = Pick<
  PredictionLiveDataGoal,
  "scorerName" | "timeMin" | "type"
>;

const MatchGoal = ({ scorerName, timeMin, type }: MatchGoalProps) => (
  <li className={styles.stat}>
    {type === "G"
      ? `(${timeMin}') ${scorerName}`
      : `(${timeMin}') ${scorerName} (${type})`}
  </li>
);

type MatchCardRowProps = Pick<PredictionsMatch, "contestant"> & {
  card: PredictionLiveDataCard[];
};

const MatchCardRows = ({
  card,
  contestant,
}: MatchCardRowProps): ReactElement => {
  const homeCards = filterStatsByContestantId(card, contestant[0]);
  const awayCards = filterStatsByContestantId(card, contestant[1]);
  const homeYellowCards = homeCards.filter((card) => card.type === "YC");
  const awayYellowCards = awayCards.filter((card) => card.type === "YC");
  const hasYellowCards = [...homeYellowCards, ...awayYellowCards].length > 0;
  const homeRedCards = homeCards.filter((card) => card.type !== "YC");
  const awayRedCards = awayCards.filter((card) => card.type !== "YC");
  const hasRedCards = [...homeRedCards, ...awayRedCards].length > 0;

  return (
    <>
      <MatchCardRow
        contestant={contestant}
        homeCards={homeYellowCards}
        awayCards={awayYellowCards}
        cardType="yellow"
        hasCards={hasYellowCards}
      />
      <MatchCardRow
        contestant={contestant}
        homeCards={homeRedCards}
        awayCards={awayRedCards}
        cardType="red"
        hasCards={hasRedCards}
      />
    </>
  );
};

type MatchGoalRowProps = Pick<PredictionsMatch, "contestant"> & {
  goal: PredictionLiveDataGoal[];
};

const MatchGoalRow = ({
  goal,
  contestant,
}: MatchGoalRowProps): ReactElement => {
  const hasGoals = goal.length > 0;
  const homeGoals = hasGoals ? filterGoalsForTeam(goal, contestant[0]) : [];
  const awayGoals = hasGoals ? filterGoalsForTeam(goal, contestant[1]) : [];

  return (
    <div className={styles.statRow}>
      <ul aria-label={`${contestant[0].name} goals`}>
        {hasGoals &&
          homeGoals.map(
            ({ scorerId, scorerName, type, timeMin, timeMinSec }) => (
              <MatchGoal
                scorerName={scorerName}
                type={type}
                timeMin={timeMin}
                key={`${scorerId}-goal-${timeMinSec}`}
              />
            )
          )}
      </ul>
      {hasGoals && <FootballIcon />}
      <ul aria-label={`${contestant[1].name} goals`}>
        {hasGoals &&
          awayGoals.map(
            ({ scorerId, scorerName, timeMinSec, timeMin, type }) => (
              <MatchGoal
                scorerName={scorerName}
                timeMin={timeMin}
                type={type}
                key={`${scorerId}-goal-${timeMinSec}`}
              />
            )
          )}
      </ul>
    </div>
  );
};

type MatchEventsProps = Pick<PredictionsMatch, "liveData" | "contestant">;

export const MatchEvents = ({
  liveData,
  contestant,
}: MatchEventsProps): ReactElement => {
  return (
    <MatchStatContainer header="Overview">
      <>
        {liveData && Array.isArray(liveData.goal) && (
          <MatchGoalRow goal={liveData.goal} contestant={contestant} />
        )}
        {liveData && Array.isArray(liveData.card) && (
          <MatchCardRows contestant={contestant} card={liveData.card} />
        )}
      </>
    </MatchStatContainer>
  );
};
