import React, { ReactElement } from "react";

interface FootballIconProps {
  title?: string;
  fill?: string;
}

export const FootballIcon = ({
  title = "Football",
  fill = "#b3cadb",
}: FootballIconProps): ReactElement => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-1792.000000, -197.000000)"
        fill={fill}
        fillRule="nonzero"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <g id="Group-3">
              <path
                d="M102,100 C105.313708,100 108,102.686292 108,106 C108,109.313708 105.313708,112 102,112 C98.6862915,112 96,109.313708 96,106 C96,102.686292 98.6862915,100 102,100 Z M102,100.9 C99.1833478,100.9 96.9,103.183348 96.9,106 C96.9,108.816652 99.1833478,111.1 102,111.1 C104.816652,111.1 107.1,108.816652 107.1,106 C107.1,103.183348 104.816652,100.9 102,100.9 Z M100.618843,108.521883 L101.197354,110.174316 C100.222922,109.98807 99.3666012,109.469087 98.7511603,108.740133 L98.7423769,108.522522 L100.618843,108.521883 Z M103.589914,108.521883 L105.310008,108.522522 L105.304838,108.672405 C104.698664,109.4211 103.843226,109.959703 102.864783,110.161956 L103.589914,108.521883 Z M102.026192,104.372211 L103.746286,105.649034 L103.120797,107.564267 L101.026036,107.564267 L100.306099,105.649034 L102.026192,104.372211 Z M98.9566067,103.414595 L99.2114934,105.170225 L97.7506703,106.076249 L97.75,106 L97.75,106 C97.75,105.04899 98.0623609,104.170937 98.5900838,103.462837 L98.9566067,103.414595 Z M105.095778,103.414595 L105.404014,103.454937 C105.935309,104.1644 106.25,105.04545 106.25,106 L106.249,106.043 L104.840891,105.170225 L105.095778,103.414595 Z M102,101.75 C102.450762,101.75 102.885133,101.820175 103.292786,101.950195 L103.433542,102.156606 L102.026192,103.254992 L100.460125,102.230342 L100.635379,101.973819 C101.063695,101.828695 101.522658,101.75 102,101.75 Z"
                id="Football"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
