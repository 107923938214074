import { ReactElement, useState } from "react";
import { PercentageStatPair } from "@/components/PercentageStatPair/PercentageStatPair";
import { BookingStatPair } from "@/components/BookingStatPair/BookingStatPair";
import {
  PredictionLiveDataLineups,
  PredictionTeamLineupStats,
} from "@/types/api/predictions";
import { ButtonGroup } from "@/components/ButtonGroup/ButtonGroup";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";
import styles from "@/components/MatchGeneralStats/MatchGeneralStats.module.scss";

type MatchState = "value" | "fh" | "sh" | "efh" | "esh";
const matchStates = ["value", "fh", "sh", "efh", "esh"];
const isMatchState = (value: string): value is MatchState =>
  matchStates.includes(value);

const statValueOrZero = (
  stat: PredictionTeamLineupStats | undefined,
  matchState: MatchState = "value"
): number => {
  const statValue = stat && stat[matchState];
  return statValue ? +statValue : 0;
};

interface MatchGeneralStatsProps {
  lineups: PredictionLiveDataLineups;
}

export const MatchGeneralStats = ({
  lineups: { home, away },
}: MatchGeneralStatsProps): ReactElement | null => {
  const [matchState, setMatchState] = useState<MatchState>("value");

  const homeTeamName = home.teamName;
  const awayTeamName = away.teamName;
  const homePossessionStats = home.stats.find(
    (stat) => stat.type === "possessionPercentage"
  );
  const awayPossessionStats = away.stats.find(
    (stat) => stat.type === "possessionPercentage"
  );
  const homeShotStats = home.stats.find(
    (stat) => stat.type === "totalScoringAtt"
  );
  const awayShotStats = away.stats.find(
    (stat) => stat.type === "totalScoringAtt"
  );
  const homeShotsOnTargetStat = home.stats.find(
    (stat) => stat.type === "ontargetScoringAtt"
  );
  const awayShotsOnTargetStat = away.stats.find(
    (stat) => stat.type === "ontargetScoringAtt"
  );
  const homeCornerStat = home.stats.find((stat) => stat.type === "wonCorners");
  const awayCornerStat = away.stats.find((stat) => stat.type === "wonCorners");
  const homeYellowCardCount = home.stats.find(
    (stat) => stat.type === "totalYellowCard"
  );
  const awayYellowCardCount = away.stats.find(
    (stat) => stat.type === "totalYellowCard"
  );
  const homeRedCardCount = home.stats.find(
    (stat) => stat.type === "totalRedCard"
  );
  const awayRedCardCount = away.stats.find(
    (stat) => stat.type === "totalRedCard"
  );

  if (
    !homePossessionStats &&
    !awayPossessionStats &&
    !homeShotStats &&
    !awayShotStats &&
    !homeShotsOnTargetStat &&
    !awayShotsOnTargetStat &&
    !homeCornerStat &&
    !awayCornerStat
  )
    return null;

  const onClickHandler = (name: string) => {
    if (isMatchState(name)) setMatchState(name);
  };

  const buttons = [
    {
      name: "value",
      text: "Full Time",
      onClick: onClickHandler,
      active: matchState === "value",
    },
    {
      name: "fh",
      text: "1st Half",
      onClick: onClickHandler,
      active: matchState === "fh",
    },
  ];

  const hasSecondHalfStats = homePossessionStats?.sh !== undefined;

  if (hasSecondHalfStats) {
    buttons.push({
      name: "sh",
      text: "2nd Half",
      onClick: onClickHandler,
      active: matchState === "sh",
    });
  }

  const hasExtraTime = homePossessionStats?.efh !== undefined;

  if (hasExtraTime) {
    buttons.push({
      name: "efh",
      text: "FH ET",
      onClick: onClickHandler,
      active: matchState === "efh",
    });
  }

  const hasSecondHalfExtraTime = homePossessionStats?.esh !== undefined;

  if (hasSecondHalfExtraTime) {
    buttons.push({
      name: "esh",
      text: "SH ET",
      onClick: onClickHandler,
      active: matchState === "esh",
    });
  }

  return (
    <MatchStatContainer data-testid="MatchGeneralStats" header="General">
      <>
        <ButtonGroup buttons={buttons} />
        <div className={styles.percentageStatPairsContainer}>
          <PercentageStatPair
            homeStatValue={statValueOrZero(homePossessionStats, matchState)}
            homeTeamName={homeTeamName}
            awayStatsValue={statValueOrZero(awayPossessionStats, matchState)}
            awayTeamName={awayTeamName}
            title="Possession"
            labelAsPercentage={true}
          />

          <PercentageStatPair
            homeStatValue={statValueOrZero(homeShotStats, matchState)}
            homeTeamName={homeTeamName}
            awayStatsValue={statValueOrZero(awayShotStats, matchState)}
            awayTeamName={awayTeamName}
            title="Shots"
          />
          <PercentageStatPair
            homeStatValue={statValueOrZero(homeShotsOnTargetStat, matchState)}
            homeTeamName={homeTeamName}
            awayStatsValue={statValueOrZero(awayShotsOnTargetStat, matchState)}
            awayTeamName={awayTeamName}
            title={"Shots on Target"}
          />
          <PercentageStatPair
            homeStatValue={statValueOrZero(homeCornerStat, matchState)}
            homeTeamName={homeTeamName}
            awayStatsValue={statValueOrZero(awayCornerStat, matchState)}
            awayTeamName={awayTeamName}
            title={"Corners"}
          />
        </div>
        <div className={styles.bookingStatPairsContainer}>
          <BookingStatPair
            label={homeTeamName}
            yellowCardCount={statValueOrZero(homeYellowCardCount, matchState)}
            redCardCount={statValueOrZero(homeRedCardCount, matchState)}
          />
          <BookingStatPair
            label={awayTeamName}
            yellowCardCount={statValueOrZero(awayYellowCardCount, matchState)}
            redCardCount={statValueOrZero(awayRedCardCount, matchState)}
            reverse={true}
          />
        </div>
      </>
    </MatchStatContainer>
  );
};
