import styled from "styled-components";
export const StyledLineup = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-flow: column wrap;
  height: 200px;
  justify-content: flex-start;
  font-size: 0.625rem;
  font-weight: 500;
  align-content: space-between;

  @media only screen and ${devices.laptop} {
    height: 150px;
  }
`;

import { devices } from "@/constants/devices";

export const StyledPlayer = styled.li`
  display: flex;
  flex-flow: row nowrap;
  min-width: 100px;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-y: hidden;
`;
