import { ReactElement } from "react";

interface Props {
  size?: number;
}

export const AngleLeftCircle = ({ size = 40 }: Props): ReactElement => (
  <svg
    width={`${size}px`}
    height={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    transform="rotate(180)"
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="16" cy="16" r="16" fill="#1E2A3A" />
      <g transform="translate(5 4)">
        <polygon points="0 0 24 0 24 24 0 24" />
        <polygon
          fill="#FFF"
          fillRule="nonzero"
          points="13.172 12 8.222 7.05 9.636 5.636 16 12 9.636 18.364 8.222 16.95"
        />
      </g>
    </g>
  </svg>
);
