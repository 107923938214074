export const AssistIcon = () => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Assist</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Stats-(Live)-Web"
        transform="translate(-1808.000000, -197.000000)"
        fill="#B6CBD8"
        fillRule="nonzero"
      >
        <g id="Lineup" transform="translate(1622.000000, 50.000000)">
          <g id="Players" transform="translate(74.000000, 47.000000)">
            <g id="Group-3">
              <path
                d="M118,100 C121.313708,100 124,102.686292 124,106 C124,109.313708 121.313708,112 118,112 C114.686292,112 112,109.313708 112,106 C112,102.686292 114.686292,100 118,100 Z M118,100.9 C115.183348,100.9 112.9,103.183348 112.9,106 C112.9,108.816652 115.183348,111.1 118,111.1 C120.816652,111.1 123.1,108.816652 123.1,106 C123.1,103.183348 120.816652,100.9 118,100.9 Z M118.024233,102.103037 C118.14055,102.103037 118.248788,102.137577 118.34895,102.206659 C118.449112,102.275741 118.521809,102.372783 118.567044,102.497788 L120.951535,108.280891 C120.983845,108.366421 121,108.445371 121,108.517742 C121,108.688802 120.945073,108.828608 120.835218,108.937165 C120.725363,109.045722 120.599355,109.1 120.457189,109.1 C120.340872,109.1 120.235865,109.063815 120.142165,108.991443 C120.048465,108.919072 119.975768,108.820385 119.924071,108.69538 L119.420032,107.501258 L116.531502,107.501258 L116.027464,108.705249 C115.982229,108.830254 115.912763,108.927296 115.819063,108.996378 C115.725363,109.06546 115.623587,109.1 115.513732,109.1 C115.345718,109.1 115.218094,109.053946 115.130856,108.961837 C115.043618,108.869728 115,108.744725 115,108.586824 C115,108.527611 115.009693,108.465109 115.029079,108.399317 L117.442649,102.497788 C117.494346,102.366203 117.57189,102.264227 117.675283,102.191856 C117.778676,102.119484 117.894991,102.089878 118.024233,102.103037 Z M117.966074,104.057055 L117.006462,106.366349 L118.945073,106.366349 L117.966074,104.057055 Z"
                id="Assist"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
