import { ReactElement } from "react";
import { PredictionStatsMatch } from "@/types/api/predictions";
import { PreviousMatchResult } from "@/components/PreviousMatchResult/PreviousMatchResult";
import styles from "@/components/PreviousMatchResults/PreviousMatchResults.module.scss";

interface PreviousMatchResultsProps {
  matches: PredictionStatsMatch[];
  activeTeamId: string;
}

export const PreviousMatchResults = ({
  matches,
  activeTeamId,
}: PreviousMatchResultsProps): ReactElement => (
  <div className={styles.container}>
    {matches.map((match) => (
      <PreviousMatchResult
        match={match}
        activeTeamId={activeTeamId}
        key={match.id}
      />
    ))}
  </div>
);
