import { ReactElement } from "react";
import {
  PredictionTeamLineupPlayerStats,
  PredictionTeamLineupPlayerWithTeamName,
} from "@/types/api/predictions";
import {
  StyledPlayerStatContainer,
  StyledPlayerStatName,
  StyledPlayerStatTeam,
  StyledPlayerStatValue,
} from "@/components/PlayerStat/PlayerStat.style";

interface PlayerStatProps {
  player: PredictionTeamLineupPlayerWithTeamName;
  stat: keyof PredictionTeamLineupPlayerStats;
}

export const PlayerStat = ({
  player: { stats, name, teamName },
  stat,
}: PlayerStatProps): ReactElement => {
  const activeStatValue = stats[stat];

  return (
    <StyledPlayerStatContainer data-testid={`player-${stat}-stat`}>
      <StyledPlayerStatName>{name} </StyledPlayerStatName>
      <StyledPlayerStatTeam>{teamName} </StyledPlayerStatTeam>
      <StyledPlayerStatValue>
        {stat === "shots"
          ? `${activeStatValue} (${stats.shotsOnTarget})`
          : activeStatValue}
      </StyledPlayerStatValue>
    </StyledPlayerStatContainer>
  );
};
