import { ReactElement } from "react";
import { FootballIcon } from "@/components/icons/FootballIcon";
import { AssistIcon } from "@/components/icons/AssistIcon";
import { YellowCardIcon } from "@/components/icons/YellowCardIcon";
import { RedCardIcon } from "@/components/icons/RedCardIcon";
import { SubOnIcon } from "@/components/icons/SubbedOnIcon";
import { SubOffIcon } from "@/components/icons/SubOffIcon";
import { IconWithCount } from "@/components/IconWithCount/IconWithCount";
import {
  StyledPlayerWithStats,
  StyledPlayerWithStatsName,
  StyledStatIconContainer,
} from "@/components/PlayerWithStatIcons/PlayerWithStatsIcons.style";
import { OwnGoalIcon } from "@/components/icons/OwnGoalIcon";
import { MissedPenaltyIcon } from "@/components/icons/MissedPenaltyIcon";
import { PlayerPositionGroups } from "@/types/api/predictions";

interface PlayerWithStatIconsProps {
  name: string;
  goals?: number;
  ownGoals?: number;
  assists?: number;
  missedPens?: number;
  yellowCards?: boolean;
  redCards?: boolean;
  subbedOn?: boolean;
  subbedOff?: boolean;
  positionGroup: PlayerPositionGroups;
}

export const PlayerWithStatIcons = ({
  name,
  positionGroup,
  goals = 0,
  ownGoals = 0,
  assists = 0,
  missedPens = 0,
  yellowCards = false,
  redCards = false,
  subbedOn = false,
  subbedOff = false,
}: PlayerWithStatIconsProps): ReactElement => {
  const goalLabel = "Goal";

  return (
    <StyledPlayerWithStats>
      <StyledPlayerWithStatsName>{`${
        positionGroup === "" ? positionGroup : `${positionGroup} `
      }${name}`}</StyledPlayerWithStatsName>
      <StyledStatIconContainer>
        {goals > 0 && (
          <IconWithCount count={goals} label={goalLabel}>
            <FootballIcon title={goalLabel} fill={"#44DB5E"} />
          </IconWithCount>
        )}
        {ownGoals > 0 && (
          <IconWithCount count={ownGoals} label={"Own Goal"}>
            <OwnGoalIcon />
          </IconWithCount>
        )}
        {assists > 0 && (
          <IconWithCount count={assists} label={"Assist"}>
            <AssistIcon />
          </IconWithCount>
        )}
        {missedPens > 0 && (
          <IconWithCount count={missedPens} label={"Missed Penalty"}>
            <MissedPenaltyIcon />
          </IconWithCount>
        )}
        {yellowCards && <YellowCardIcon />}
        {redCards && <RedCardIcon />}
        {subbedOn && <SubOnIcon />}
        {subbedOff && <SubOffIcon />}
      </StyledStatIconContainer>
    </StyledPlayerWithStats>
  );
};
