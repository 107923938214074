import { useRef, useState } from "react";
import { PredictionInsights } from "@/types/api/predictions";
import CardWrapper from "../CardWrapper/CardWrapper";
import styles from "./MatchOverviewInsights.module.scss";

interface MatchOverviewInsightsProps {
  insights: PredictionInsights[];
}

export const MatchOverviewInsights = ({
  insights,
}: MatchOverviewInsightsProps) => {
  const defaultInsightCount = 2;
  const [activeInsights, setActiveInsights] = useState(
    insights.slice(0, defaultInsightCount)
  );
  const [viewAll, setViewAll] = useState(false);
  const insightsContainerRef = useRef<HTMLDivElement>(null);

  if (insights.length === 0) return null;

  const toggleShowAll = () => {
    setViewAll(!viewAll);
    if (viewAll) {
      setActiveInsights(insights.slice(0, defaultInsightCount));
      scrollToRef();
    } else {
      setActiveInsights(insights);
    }
  };

  const scrollToRef = () => {
    if (!insightsContainerRef.current) return;
    window.scrollTo({
      top: insightsContainerRef.current.offsetTop - 150,
      behavior: "smooth",
    });
  };

  return (
    <div ref={insightsContainerRef}>
      <CardWrapper listHeader="Insights">
        <ul className={styles.insightsList}>
          {activeInsights.map((insight, i) => (
            <li key={insight.id}>
              {i === 0 ? null : <hr className={styles.insightSeparator} />}
              <p className={styles.insight}>{insight.fact}</p>
            </li>
          ))}
        </ul>
        {insights.length > defaultInsightCount ? (
          <div className={styles.insightsButtonContainer}>
            <button onClick={toggleShowAll}>
              {viewAll ? "View Less" : "View All"}
            </button>
          </div>
        ) : null}
      </CardWrapper>
    </div>
  );
};
