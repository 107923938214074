import { ReactElement } from "react";
import { PredictionStats } from "@/types/api/predictions";
import { MatchStatContainer } from "@/components/MatchStatContainer/MatchStatContainer";
import { filterHeadToHeadStats } from "@/lib/predictions";
import styles from "@/components/MatchHeadToHead/MatchHeadToHead.module.scss";
import { HeadToHeadRow } from "@/components/HeadToHeadRow/HeadToHeadRow";

interface MatchHeadToHeadProps {
  stats: PredictionStats;
}

export const MatchHeadToHead = ({
  stats,
}: MatchHeadToHeadProps): ReactElement | null => {
  const { matches } = filterHeadToHeadStats(stats)[0];

  return (
    <MatchStatContainer header={"Head To Head"}>
      <div className={styles.headToHeadRowContainer}>
        {matches.map((match) => (
          <HeadToHeadRow key={match.id} match={match} />
        ))}
      </div>
    </MatchStatContainer>
  );
};
